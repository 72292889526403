<template>
  <section>
    <CContainer>
      <CForm @submit.stop.prevent="Save()">
        <CCard>
          <CCardHeader class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0">{{ Action === 'Edit' ? $t('Global.Edit') : $t('Global.Add') }}{{ $t('Navigation.Content/FAQ') }}</h5>
            <div class="card-header-actions d-flex">
              <WidgetsLocales v-if="$store.state.user.permission.MutipleLocale" :NowChooseLanguage.sync="NowChooseLanguage"></WidgetsLocales>
              <CButton v-if="!$store.state.user.permission.MutipleLocale" color="success" type="submit">
                {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
              </CButton>
            </div>
          </CCardHeader>
          <CCardBody>
            <CTabs fade addTabsWrapperClasses="tab-info">
              <CTab :title="$t('Post/Detail.Info')" active>
                <CRow>
                  <CCol md="8">
                    <template v-if="NowChooseLanguage === 'default'">
                      <CInput
                          :label="$t('Post/Detail.Name')"
                          v-model="Data.Name"
                          horizontal
                      />
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Post/Detail.Tags') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                              :key="NowChooseLanguage"
                              v-model="Data.Tags"
                              :tag-placeholder="$t('Global.EnterToAdd')"
                              :placeholder="$t('Global.PleaseKeyIn')"
                              :deselectLabel="$t('Global.DeselectLabel')"
                              :selectedLabel="$t('Global.SelectedLabel')"
                              :options="Data.Tags"
                              :multiple="true"
                              :taggable="true"
                              @tag="AddTag">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                    </template>
                    <template v-else>
                      <CInput
                          :label="$t('Post/Detail.Name')"
                          v-model="Data.LanguageData[NowChooseLanguage].Name"
                          horizontal
                      />
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Post/Detail.Tags') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                              :key="NowChooseLanguage"
                              v-model="Data.LanguageData[NowChooseLanguage].Tags"
                              :tag-placeholder="$t('Global.EnterToAdd')"
                              :placeholder="$t('Global.PleaseKeyIn')"
                              :deselectLabel="$t('Global.DeselectLabel')"
                              :selectedLabel="$t('Global.SelectedLabel')"
                              :options="Data.LanguageData[NowChooseLanguage].Tags"
                              :multiple="true"
                              :taggable="true"
                              @remove="RemoveLocaleTag"
                              @tag="AddTag">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                    </template>
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Navigation.Content/FAQ') + $t('Global.Category') }}
                      </CCol>
                      <CCol sm="9">
                        <multiselect
                          v-model="SelectCategories"
                          :options="CategoryList"
                          :optionHeight="34"
                          label="label"
                          track-by="value"
                          :placeholder="$t('Global.PleaseSelect')"
                          :selectLabel="$t('Global.SelectLabel')"
                          :selectedLabel="$t('Global.SelectedLabel')"
                          :deselectLabel="$t('Global.DeselectLabel')"
                          :multiple="true"
                          @input="SynchronizeCategories()"
                        >
                          <template #noOptions>
                            {{ $t('Global.NoOptions') }}
                          </template>
                        </multiselect>
                      </CCol>
                    </CRow>
                    <Editor api-key="x3zt0pfu7uw915eab118wco1xsrza3rhkysn57vzbfmivqxo" :init="TinyMCE" v-model="CurrentContent" />
                  </CCol>
                  <CCol md="4">
                    <CInput
                      :label="$t('Post/Detail.Slug')"
                      v-model="Data.Slug"
                      horizontal
                    />
                    <CRow>
                      <CCol tag="label" sm="3" class="col-form-label" for="CreateTime">
                        {{ $t('Post/Detail.CreateTime') }}
                      </CCol>
                      <CCol sm="9">
                        <v-date-picker v-model="Data.CreateTime" mode="dateTime" is24hr>
                          <template v-slot="{ inputValue, inputEvents }">
                            <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                              <template #append-content>
                                <CIcon name="cil-calendar"/>
                              </template>
                            </CInput>
                          </template>
                        </v-date-picker>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CTab>
            </CTabs>
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton color="success" type="submit">
              {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
            </CButton>
          </CCardFooter>
        </CCard>
        <CElementCover v-if="(Loading === true)" :opacity="0.75">
          <CSpinner color="success"/>
        </CElementCover>
      </CForm>
    </CContainer>
    <MediaStore :Config="MediaStoreConfig" ref="MediaStore" @OK="SetImages(ChooseImageType)"></MediaStore>
    <input id="tinymce-image-input" type="text" class="d-none">
  </section>
</template>

<route>
{
  "meta": {
    "label": "編輯顧客問與答"
  }
}
</route>

<script>
import DataSchema from '@/schema/post/detail'
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'

export default {
  name: 'EditPost',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
    MediaStore: () => import('@/components/public/Media'),
    WidgetsLocales: () => import('@/components/widgets/WidgetsLocales'),
    Editor
  },
  data () {
    return {
      Action: 'Edit',
      Data: {}, //主資料
      Features: [],
      OldSlug: '',
      SelectCategories: [], //格式化後的已選商品分類
      PostStatus: false, //格式化後的狀態開關
      EnableResponseContent: false, //格式化後的響應式商品內容開關
      CategoryList: [],
      VariableList: [],
      TinyMCE: {
        ...TinyMCESetting,
        file_picker_callback: (callback) => {
          this.OpenMediaStore('Editor', false)
          const input = document.getElementById('tinymce-image-input')
          input.addEventListener('change', (event) => {
            callback(event.target.value, {title: ''})
          })
        },
      },
      NowChooseDevice: 'Web',
      NowChooseLanguage: 'default',
      Loading: false,
      ChooseImageType: '',
      ChooseImageIndex: false,
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      MediaMetaConfig: {
        Display: false,
        ImageMeta: {
          URL: ''
        }
      }
    }
  },
  computed: {
    CurrentContent: {
      get () {
        if (this.NowChooseLanguage !== 'default') {
          if (this.Data.LanguageData[this.NowChooseLanguage]) {
            if (this.EnableResponseContent) {
              switch (this.NowChooseDevice) {
                case 'Web':
                case 'Mobile':
                  if (this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice]) {
                    return this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice].Content
                  }
              }
            }
            return this.Data.LanguageData[this.NowChooseLanguage].Content
          }
        }
        if (this.EnableResponseContent) {
          switch (this.NowChooseDevice) {
            case 'Web':
            case 'Mobile':
              if (this.Data.ResponseData[this.NowChooseDevice]) {
                return this.Data.ResponseData[this.NowChooseDevice].Content
              }
          }
        }
        return this.Data.Content
      },
      set (value) {
        if (this.NowChooseLanguage !== 'default') {
          if (this.Data.LanguageData[this.NowChooseLanguage]) {
            if (this.EnableResponseContent) {
              switch (this.NowChooseDevice) {
                case 'Web':
                case 'Mobile':
                  if (this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice]) {
                    this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice].Content = value
                  }
              }
            } else {
              this.Data.LanguageData[this.NowChooseLanguage].Content = value
            }
          }
        } else {
          if (this.EnableResponseContent) {
            switch (this.NowChooseDevice) {
              case 'Web':
              case 'Mobile':
                if (this.Data.ResponseData[this.NowChooseDevice]) {
                  this.Data.ResponseData[this.NowChooseDevice].Content = value
                }
            }
          } else {
            this.Data.Content = value
          }
        }
      }
    },
    StatusOptions () {
      return Object.keys(this.$t('Post/Detail.StatusOptions')).map(status => {
        return {
          value: parseInt(status),
          label: this.$t('Post/Detail.StatusOptions')[status]
        }
      })
    }
  },
  created() {
    this.$set(this.$data, 'Data', Object.assign({}, DataSchema))
    this.Action = (this.$route.name === 'content-faq-add' ? 'Add' : 'Edit')
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    Init() {
      const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
      this.Features = PermissionSession.Features.Post ? (PermissionSession.Features.Post.Add || []) : []
      const Functions = [
        this.GetCategories(),
      ]
      if (this.Action === 'Edit') Functions.push(this.GetData())
      return Promise.all(Functions).then(() => {
        //詳細資料處理
        if (this.Action === 'Edit') this.HandleDetailData()
        //多語系資料處理
        if (this.$store.state.user.permission.MutipleLocale && this.Action === 'Add') {
          this.$set(this.Data, 'LanguageData', {})
          this.$store.state.user.permission.Locales.forEach((locale) => {
            this.$set(this.Data.LanguageData, locale, {
              Name: '',
              OtherName: '',
              Introduction: '',
              HeaderText: '',
              Content: '',
              Attachment: '',
              Tags: [],
              SEO: {
                Description: '',
                Keywords: ''
              },
              Cover: '',
              Carousel: [],
              Gallery: [],
              OverViewImages: [],
              ResponseData: {
                Mobile: {
                  Content: ''
                },
                Web: {
                  Content: ''
                }
              }
            })
          })
        }
        this.NowChooseLanguage = this.$store.state.user.permission.MutipleLocale ? this.$store.state.user.permission.DefaultLocale : 'default'
        return true
      }).catch((err) => {
        throw err
      })
    },
    Save() {
      let Action
      this.Data.Slug = this.Data.Slug.replace(/\//g, '_')
      switch (this.Action) {
        case 'Edit':
          Action = this.Edit()
          break
        case 'Add':
          Action = this.Add()
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Post/SaveFail-1'),
            type: 'error'
          })
          return
      }
      this.Loading = true
      Action.then((result) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: result.msg,
          type: 'success'
        })
        if (this.Action === 'Add') {
          this.$router.replace('/content/faq/' + result.data.Slug)
        }
        if (this.Action === 'Edit' && this.OldSlug !== this.Data.Slug) {
          this.$router.replace('/content/faq/' + this.Data.Slug)
        }
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error') + '[' + err.code  + ']',
          text: this.$t('Message.Post/SaveFail-2') + err.msg,
          type: 'error'
        })
      })
    },
    Add() {
      if (this.Data.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.Post/SlugEmpty')
        })
      }
      //語系資料同步到預設值
      if (this.$store.state.user.permission.MutipleLocale) {
        this.SynchronizeDefaultLocaleData()
      }
      // 若沒有代稱則使用名稱直接帶入代稱
      if (!this.Data.Slug) {
        this.Data.Slug = this.Data.Name + '-' + this.$dayjs().unix()
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/content/add',
        method: 'post',
        data: {
          ...this.Data,
          Categories: [
            'faq',
            ...this.Data.Categories
          ],
          Status: parseInt(this.Data.Status),
          EnableResponseContent: this.EnableResponseContent ? 1 : 0
        }
      }).then(({data}) => {
        return {
          ...data,
          msg: this.$t('Message.Post/AddSuccess')
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit() {
      if (this.Data.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.Post/SlugEmpty')
        })
      }
      //語系資料同步到預設值
      if (this.$store.state.user.permission.MutipleLocale) {
        this.SynchronizeDefaultLocaleData()
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/content/edit',
        method: 'post',
        data: {
          slug: this.OldSlug,
          updateData: {
            ...this.Data,
            Categories: [
              'faq',
              ...this.Data.Categories
            ],
            Status: parseInt(this.Data.Status),
            EnableResponseContent: this.EnableResponseContent ? 1 : 0
          }
        }
      }).then(() => {
        return {
          msg: this.$t('Message.Post/EditSuccess')
        }
      }).catch((err) => {
        throw err
      })
    },
    GetData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/content/detail',
        method: 'get',
        params: {
          slug: this.$route.params.slug
        }
      }).then(({data}) => {
        this.Loading = false
        this.Data = data.detail
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetCategories () {
      return this.$store.dispatch('InnerRequest', {
        url: '/content/category/detail',
        method: 'get',
        params: {
          slug: 'faq'
        }
      }).then(({data}) => {
        this.CategoryList = data.categories.map((category) => {
          return { label: category.Name, value: category.Slug }
        })
      }).catch((err) => {
        throw err
      })
    },
    HandleDetailData() {
      this.SelectCategories = this.CategoryList.filter((data) => {
        return this.Data.Categories.includes(data.value)
      })

      this.OldSlug = this.Data.Slug
      this.PostStatus = (this.Data.Status === 1)
      this.EnableResponseContent = (this.Data.EnableResponseContent === 1)

        //自定義參數載入
        if (this.Data.Variable) {
            this.VariableList = Object.keys(this.Data.Variable).map((data) => {
                let Value, Type = typeof this.Data.Variable[data]
                switch (typeof this.Data.Variable[data]) {
                    case 'boolean':
                    case 'string':
                    case 'undefined':
                    case 'number':
                        Value = this.Data.Variable[data]
                        break
                    case 'object':
                        Value = JSON.stringify(this.Data.Variable[data])
                        if (Array.isArray(this.Data.Variable[data])) {
                            Type = 'array'
                        }
                        break
                }
                return {
                    Index: data,
                    Value,
                    Type
                }
            })
        }

      //多語系資料處理
      if (this.$store.state.user.permission.MutipleLocale) {
        if (!this.Data.LanguageData) {
          this.$set(this.Data, 'LanguageData', {})
          this.$store.state.user.permission.Locales.forEach((locale) => {
            this.$set(this.Data.LanguageData, locale, {
              Name: '',
              OtherName: '',
              Introduction: '',
              HeaderText: '',
              Content: '',
              Attachment: '',
              Tags: [],
              SEO: {
                Description: '',
                Keywords: ''
              },
              Cover: '',
              Carousel: [],
              Gallery: [],
              OverViewImages: [],
              ResponseData: {
                Mobile: {
                  Content: ''
                },
                Web: {
                  Content: ''
                }
              }
            })
          })
        }
        this.NowChooseLanguage = this.$store.state.user.permission.DefaultLocale || 'default'
      }
    },
    SynchronizeDefaultLocaleData() {
      const DefaultLocale = this.$store.state.user.permission.DefaultLocale
      if (DefaultLocale !== 'default') {
        this.Data.Name = this.Data.LanguageData[DefaultLocale].Name
        this.Data.OtherName = this.Data.LanguageData[DefaultLocale].OtherName
        this.Data.Introduction = this.Data.LanguageData[DefaultLocale].Introduction
        this.Data.HeaderText = this.Data.LanguageData[DefaultLocale].HeaderText
        this.Data.Content = this.Data.LanguageData[DefaultLocale].Content
        this.Data.Attachment = this.Data.LanguageData[DefaultLocale].Attachment
        this.Data.SEO = this.Data.LanguageData[DefaultLocale].SEO
        this.Data.Cover = this.Data.LanguageData[DefaultLocale].Cover
        this.Data.Carousel = this.Data.LanguageData[DefaultLocale].Carousel
        this.Data.Gallery = this.Data.LanguageData[DefaultLocale].Gallery
        this.Data.OverViewImages = this.Data.LanguageData[DefaultLocale].OverViewImages
        this.Data.ResponseData = this.Data.LanguageData[DefaultLocale].ResponseData
      }
    },
    SynchronizeCategories() {
      this.Data.Categories = this.SelectCategories.map(data => {
        return data.value
      })
    },
    AddTag (newTag) {
      this.Data.Tags.push(newTag)
      if (this.NowChooseLanguage !== 'default') {
        this.Data.LanguageData[this.NowChooseLanguage].Tags.push(newTag)
      }
    },
    RemoveLocaleTag (removedOption) {
      this.Data.Tags.splice(this.Data.Tags.indexOf(removedOption), 1)
    },
    OpenMediaStore(type = 'Carousel', multiple = true, index = false) {
      this.ChooseImageType = type
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = multiple
      if (index !== false) {
        this.ChooseImageIndex = index
      }
    },
    async SetImages (type = 'Carousel') {
      switch (type) {
        case 'Cover':
        case 'Attachment':
          this.SetSingleImage(type)
          break
        case 'Editor':
          document.getElementById('tinymce-image-input').value = await this.ResolveImageURL()
          document.getElementById('tinymce-image-input').dispatchEvent(new Event('change'));
          break
        default:
          this.SetMultipleImages(type)
          break
      }
    },
    SetSingleImage(type, index) {
      if (this.$store.state.user.permission.StaticDomain) {
        if (this.NowChooseLanguage === 'default') {
          if (index) this.Data[type][index] = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
          else this.$set(this.Data, type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
        } else {
          if (index) this.Data.LanguageData[this.NowChooseLanguage][type][index] = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
          else this.$set(this.Data.LanguageData[this.NowChooseLanguage], type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
        }
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          if (this.NowChooseLanguage === 'default') {
            if (index) this.Data[type][index] = URL
            else this.$set(this.Data, type, URL)
          } else {
            if (index) this.Data.LanguageData[this.NowChooseLanguage][type][index] = URL
            else this.$set(this.Data.LanguageData[this.NowChooseLanguage], type, URL)
          }
        })
      }
    },
    SetMultipleImages(type) {
      if (typeof this.Data[type] === 'undefined') {
        this.$set(this.Data, type, [])
      }
      this.ChooseImageType = ''
      const storageInstance = this.$store.state.userProjectApp.storage()
      switch (typeof this.MediaStoreConfig.ChooseImageList) {
        case 'string':
          if (this.$store.state.user.permission.StaticDomain) {
            if (this.NowChooseLanguage === 'default') {
              this.$set(this.Data[type], this.ChooseImageIndex, {
                URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
                Alt: ''
              })
            } else {
              this.$set(this.Data.LanguageData[this.NowChooseLanguage][type], this.ChooseImageIndex, {
                URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
                Alt: ''
              })
            }
          } else {
            storageInstance.ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
              if (this.NowChooseLanguage === 'default') {
                this.$set(this.Data[type], this.ChooseImageIndex, {URL, Alt: ''})
              } else {
                this.$set(this.Data.LanguageData[this.NowChooseLanguage][type], this.ChooseImageIndex, {URL, Alt: ''})
              }
            })
          }
          break
        case 'object':
          if (Array.isArray(this.MediaStoreConfig.ChooseImageList)) {
            this.MediaStoreConfig.ChooseImageList.forEach(imgPath => {
              let PushData
              if (this.$store.state.user.permission.StaticDomain) {
                PushData = {
                  URL: this.$store.state.user.permission.StaticDomain + imgPath,
                  Alt: ''
                }
                if (this.NowChooseLanguage === 'default') {
                  this.Data[type].push(PushData)
                } else {
                  this.Data.LanguageData[this.NowChooseLanguage][type].push(PushData)
                }
              } else {
                storageInstance.ref().child(imgPath).getDownloadURL().then((URL) => {
                  PushData = {
                    URL,
                    Alt: ''
                  }
                  if (this.NowChooseLanguage === 'default') {
                    this.Data[type].push(PushData)
                  } else {
                    this.Data.LanguageData[this.NowChooseLanguage][type].push(PushData)
                  }
                })
              }
            })
          }
          break
      }
    },
    ResolveImageURL () {
      if (this.$store.state.user.permission.StaticDomain) {
        return Promise.resolve(this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      }
      return this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
        return URL
      })
    },
    OpenMediaMeta(type = 'Carousel', index = false) {
      this.MediaMetaConfig.Display = !this.MediaMetaConfig.Display
      this.ChooseImageType = type
      switch (type) {
        case 'Carousel':
          if (index === false) {
            return false
          }
          if (this.NowChooseLanguage === 'default') {
            this.MediaMetaConfig.ImageMeta = this.Data[type][index]
          } else {
            this.MediaMetaConfig.ImageMeta = this.Data.LanguageData[this.NowChooseLanguage][type][index]
          }
          this.ChooseImageIndex = index
          break
        case 'Cover':
          if (this.NowChooseLanguage === 'default') {
            this.MediaMetaConfig.ImageMeta.URL = this.Data[type]
          } else {
            this.MediaMetaConfig.ImageMeta.URL = this.Data.LanguageData[this.NowChooseLanguage][type]
          }
          break
      }
    },
    AddVariableArrayInput(variable) {
      if (!this.Data.Variable[variable]) {
        this.$set(this.Data.Variable, variable, [])
      }
      this.Data.Variable[variable].push({
        Value: ''
      })
    },
    RemoveVariableArrayInput(variable, index) {
      this.Data.Variable[variable].splice(index, 1)
    },
  }
}
</script>
